<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="visible"
    width="40%"
    min-width="500px"
  >
    <el-form
      :model="mail"
      :rules="rule"
      ref="mailRef"
      label-width="120px"
      class="mail-form"
    >
      <el-form-item label="业务类型" prop="bizType">
        <el-select
          v-model="mail.bizType"
          placeholder="请选择业务类型"
          style="width: 100%"
        >
          <el-option
            v-for="item in dictionary.bizType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="邮箱类型" prop="mailType">
        <el-select
          v-model="mail.mailType"
          placeholder="请选择邮箱类型"
          style="width: 100%"
        >
          <el-option
            v-for="item in dictionary.mailType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属组织" prop="orgId">
        <el-select
          v-model="mail.orgId"
          placeholder="请选择所属组织"
          style="width: 100%"
        >
          <el-option
            v-for="item in orgList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="mail.mailType == 'GR'" label="所属用户" prop="userId">
        <el-select
          v-model="mail.userId"
          placeholder="请选择所属用户"
          style="width: 100%"
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.nickName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="邮箱地址" prop="mailAddr" style="width: 100%">
        <el-input placeholder="请输入邮箱地址" v-model="mail.mailAddr">
          <template slot="append">{{ domain }}</template>
        </el-input>
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button type="default" @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { reqAddMail, reqEditMail } from "@/service/itr/mail";

export default {
  name: "ItrEditMail",
  props: {
    dictionary: {
      type: Object,
      default() {
        return {
          mailType: [],
          bizType: [],
        };
      },
    },
    userList: {
      type: Array,
      default() {
        return [];
      },
    },
    domain: {
      type: String,
    },
  },
  data() {
    return {
      mail: {},
      title: "申请专属邮箱",
      visible: false,

      orgList: [],

      rule: {
        mailType: [
          { required: true, message: "请选择邮箱类型", trigger: "change" },
        ],
        bizType: [
          { required: true, message: "请选择业务类型", trigger: "change" },
        ],
        // orgId: [
        //   { required: true, message: "请选择所属组织", trigger: "change" },
        // ],
        // userId: [
        //   { required: true, message: "请选择所属用户", trigger: "change" },
        // ],
        mailAddr: [
          {
            required: true,
            message: "请输入邮箱地址",
            trigger: "blur",
          },
          {
            pattern: /^\w+([-+.]\w+)*$/,
            message: "邮箱格式不正确",
            trigger: "blur",
          },
        ],
      },
    };
  },
  async created() {
    this.orgList = this.$store.state.user.organizations;
  },
  methods: {
    // 申请-取消
    handleCancel() {
      this.visible = false;
    },
    // 申请-提交
    async handleConfirm() {
      this.$refs.mailRef.validate(async (valid) => {
        if (valid) {
          let res;
          if (this.mail.id == null) {
            res = await reqAddMail(this.mail);
          } else {
            res = await reqEditMail(this.mail);
          }

          if (res.success) {
            this.visible = false;
            this.mail = {
              bizType: "SP",
              mailType: "ZZ",
              mailAddr: undefined,
              orgId: undefined,
            };
            this.$message.success(
              this.mail.id == null ? "申领邮箱成功！" : "修改邮箱成功！"
            );
            this.$emit("success");
          }
        }
      });
    },
    // 申请-弹窗
    showDialog(mail) {
      this.mail = mail;
      this.visible = true;
    },
  },
};
</script>

<style scoped lang="scss">
.mail-form {
  text-align: left;
  padding: 0px 80px 20px 30px;

  .el-form-item {
    margin-bottom: 40px;

    ::v-deep .el-input {
      width: 100% !important;
    }

    ::v-deep .el-input-group__append {
      line-height: 30px !important;
    }

    ::v-deep .el-form-item__error {
      top: 34px !important;
    }
  }
}
</style>
